<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card outlined class="primary--border">
          <v-card-title class="title statement-head">
            Receipt Report for
            <!-- {{selected_statement_type}} -->
            <v-menu offset-y transition="slide-y-reverse-transition">
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" text
                  >{{ selected_statement_type }}
                  <v-icon ml-2>arrow_drop_down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-subheader>Select Type</v-subheader>
                <v-list-item
                  v-for="(item, index) in statementTypes"
                  :key="index"
                  @click="(selected_statement_type = item), get()"
                >
                  <v-list-item-title>{{ item }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-spacer></v-spacer>
            <transition
              name="animate-css-transition"
              enter-active-class="animated fadeInRight"
              leave-active-class="animated fadeOutRight"
            >
              <add-button
                :on-current-batch="false"
                icon="search"
                :permission="'fee-create'"
                @action="get"
                >Search
              </add-button>
              <!--<v-btn outlined @click="get" ma-0 small v-if="this.enrollCode">Search</v-btn>-->
            </transition>
          </v-card-title>
          <v-card outlined>
            <form action="" @submit.prevent="get()">
              <input type="submit" style="display: none" />
              <v-card-title class="title">
                <v-flex xs2>
                  <!--@change="getSections(),get()"-->
                  <v-select
                    :loading="gradeLoading"
                    :disabled="grades.length < 1"
                    :items="grades"
                    class="pa-0"
                    label="Select grade"
                    v-model="grade"
                    outlined
                    dense
                  />
                </v-flex>
                <v-flex xs2>
                  <v-calendar-field
                    id-val="from_date"
                    v-model="from_date"
                    label="From Date"
                  >
                  </v-calendar-field>
                </v-flex>
                <v-flex xs2>
                  <v-calendar-field
                    id-val="to_date"
                    v-model="to_date"
                    label="To Date"
                  >
                  </v-calendar-field>
                </v-flex>
                <v-flex v-if="selected_statement_type === 'Transaction'" xs3>
                  <transaction-via v-model="transaction_by"></transaction-via>
                  <!-- <v-select
                    v-model="transaction_by"
                    :items="transaction_type"
                    label="Transaction Via"
                    multiple
                    class="pt-0"
                    outlined
                    dense
                  >
                    <v-list-item slot="prepend-item" ripple @click="toggle">
                      <v-list-item-action>
                        <v-icon
                          :color="
                            transaction_by.length > 0 ? 'indigo darken-4' : ''
                          "
                          >{{ icon }}
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-title>All</v-list-item-title>
                    </v-list-item>
                    <v-divider slot="prepend-item" class="mt-2"></v-divider>
                    <v-divider slot="append-item" class="mb-2"></v-divider>
                  </v-select> -->
                </v-flex>
                <v-flex xs2>
                  <v-text-field
                    v-model="enrollCode"
                    class="pa-0"
                    label="Enroll Code"
                    outlined
                    dense
                  />
                </v-flex>

                <v-flex xs1>
                  <transition
                    name="animate-css-transition"
                    enter-active-class="animated fadeInRight"
                    leave-active-class="animated fadeOutRight"
                  >
                    <v-btn
                      v-if="to_date || enrollCode || grade"
                      fab
                      small
                      @click="clearFilter"
                      style="z-index: 1; margin-right: 55px;"
                      class="error"
                    >
                      <v-icon dark>close</v-icon>
                    </v-btn>
                  </transition>
                </v-flex>
                <print-buttons
                  v-if="transactionList.length > 0"
                  :passAuthToReport="true"
                  labelPdf="Download Pdf"
                  labelExcel="Download Excel"
                  :downloadUrl="downloadUrl"
                  :pdf="false"
                  :excel="true"
                ></print-buttons>

                <!--<v-flex xs3 sm3></v-flex>-->
              </v-card-title>
            </form>
          </v-card>
          <br />
          <small v-if="transaction_by.length" class="pa-3"
            >Below is the {{ selected_statement_type }} receipt report list
            <span
              v-if="
                transaction_by.length === 3 &&
                  selected_statement_type === 'Transaction'
              "
            >
              on <strong>all via Cash,Cheque,Khalti and Esewa</strong>
            </span>
            <span v-else>
              <strong v-if="selected_statement_type === 'Transaction'">
                on {{ transaction_by.join(",") }}
              </strong>
            </span>
            for
            <strong
              ><span v-if="today_date === from_date">today</span> ({{
                from_date.dateForm()
              }})</strong
            >
            <strong v-if="to_date"> till ({{ to_date.dateForm() }})</strong>
            <strong v-if="grade">, {{ gradeName }}</strong>
          </small>
          <v-data-table
            :headers="
              selected_statement_type === 'Advance' ? headersAdvance : headers
            "
            :items="transactionList"
            :options.sync="pagination"
            :footer-props="footerProps"
            :expand="expand"
            :server-items-length="form.items.meta.total"
            :loading="form.loading"
            item-key="bill_no"
          >
            <template v-slot:item="{ index, item }">
              <tr
                :title="item.remarks"
                style="cursor: pointer;"
                class="tableRow"
              >
                <td class="text-xs-left">
                  <strong>{{ item.bill_no.split("-").pop() }}</strong>
                </td>
                <td class="text-xs-left">
                  {{ item.transaction_date }} <br />
                  {{ item.created_at }}
                </td>
                <td class="text-xs-left">
                  {{ item.name }}
                  <p style="font-size: 10px">
                    (Enroll Id : {{ item.enroll_id }})
                  </p>
                </td>
                <td class="text-xs-left">
                  {{ item.grade }}<sup>"{{ item.section }}"</sup>
                </td>
                <td class="text-xs-left">
                  <template v-if="selected_statement_type !== 'Advance'">
                    <strong>{{ item.amount.numberFormat() }}</strong>
                    <p v-if="item.advance_amount > 0 && item.via !== 'advance'">
                      {{ item.advance_amount.numberFormat() }}
                      (Adv.)
                    </p>
                  </template>
                  <template v-else>
                    {{ item.advance_amount.numberFormat() }}
                  </template>
                </td>
                <td
                  class="text-xs-left"
                  v-if="selected_statement_type !== 'Advance'"
                >
                  {{ item.instant_discount.numberFormat() }}
                </td>
                <td class="text-xs-left">{{ item.via.toUpperCase() }}</td>
                <td class="text-xs-left">{{ item.received_by }}</td>
                <td
                  class="text-right"
                  style="width: 50px"
                  v-if="selected_statement_type !== 'Advance'"
                >
                  <v-btn
                    v-if="
                      item.is_eod === 0 &&
                        item.via.toLowerCase() !== 'advance' &&
                        item.via.toLowerCase() !== 'khalti' &&
                        item.via.toLowerCase() !== 'esewa' &&
                        item.via.toLowerCase() !== 'connectips' &&
                        item.via.toLowerCase() !== 'imepay'
                    "
                    small
                    icon
                    color="primary"
                    class="pa-0 ma-1"
                    @click="paymentRollback(item)"
                  >
                    <v-icon dark>refresh</v-icon>
                  </v-btn>

                  <v-btn
                    v-if="$auth.can('receipt-create') && item.via !== 'advance'"
                    small
                    icon
                    color="primary"
                    class="pa-0 ma-1"
                    @click="print(item)"
                  >
                    <v-icon dark>print</v-icon>
                  </v-btn>
                  <v-btn
                    @click="transactionCorrection(item)"
                    small
                    outlined
                    v-if="$auth.isAdmin() && !item.invoice_transaction.length"
                    >Correction</v-btn
                  >
                </td>
              </tr>
            </template>

            <template
              v-slot:expand="props"
              v-if="selected_statement_type !== 'Advance'"
            >
              <v-card rounded>
                <v-card-text>
                  <span
                    v-if="props.item.invoice_transaction.length > 0"
                    style="width: 100%"
                  >
                    <table width="100%" class="inner-table">
                      <thead>
                        <tr>
                          <th class="text-xs-left">
                            <strong>Invoice Number</strong>
                          </th>
                          <th class="text-xs-left">
                            <strong>Fee Head</strong>
                          </th>
                          <th class="text-xs-left">
                            <strong>Fee Head Amount</strong>
                          </th>
                          <th class="text-xs-left">
                            <strong>Discount</strong>
                          </th>
                          <th class="text-xs-left">
                            <strong>Scholarship</strong>
                          </th>
                          <th class="text-xs-left">
                            <strong>Paid Amount</strong>
                          </th>
                          <th class="text-xs-left">
                            <strong>Instant Discount</strong>
                          </th>
                          <th class="text-xs-left">
                            <strong>Due Amount</strong>
                          </th>
                        </tr>
                      </thead>
                      <tbody
                        v-for="(x, z) in props.item.invoice_transaction"
                        :key="z"
                      >
                        <template v-for="(y, key) in x.invoice_details">
                          <tr v-if="key === 0" :key="key">
                            <td :rowspan="x.invoice_details.length">
                              {{ x.invoice_no.split("-").pop() }}
                            </td>
                            <td>{{ y.fee_head }}</td>
                            <td>{{ y.fee_head_amount }}</td>
                            <td>{{ y.discount }} %</td>
                            <td>{{ y.scholarship }} %</td>
                            <td>{{ x.amount }}</td>
                            <td>{{ x.instant_discount }}</td>
                            <td>{{ y.due_amount }}</td>
                          </tr>
                        </template>
                        <template
                          v-for="(y, key) in props.item.invoice_transaction[0]
                            .invoice_details"
                        >
                          <tr :key="key" v-if="key !== 0">
                            <td>{{ y.fee_head }}</td>
                            <td>{{ y.fee_head_amount }}</td>
                            <td>{{ y.discount }} %</td>
                            <td>{{ y.scholarship }} %</td>
                            <td>{{ x.amount }}</td>
                            <td>{{ x.instant_discount }}</td>
                            <td>{{ y.due_amount }}</td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </span>
                  <span v-else style="margin-left: 30px">
                    No record found.
                  </span>
                </v-card-text>
              </v-card>
            </template>
            <template
              v-slot:body.append
              v-if="
                from_date === to_date &&
                  transactionList.length > 0 &&
                  selected_statement_type !== 'Advance'
              "
            >
              <tr>
                <td colspan="4"><strong>Total</strong></td>
                <td class="text-xs-left">
                  <strong>{{ totalCollection.numberFormat() }}</strong>
                </td>
                <td class="text-xs-left">
                  <strong>{{ totalInstantDiscount.numberFormat() }}</strong>
                </td>
              </tr>
            </template>
            <template
              v-slot:body.append
              v-else-if="
                from_date === to_date &&
                  transactionList.length > 0 &&
                  selected_statement_type === 'Advance'
              "
            >
              <tr>
                <td colspan="4"><strong>Total</strong></td>
                <td colspan="3">
                  <strong>{{ totalAdvanceAmount.numberFormat() }}</strong>
                </td>
              </tr>
            </template>
          </v-data-table>
          <v-dialog v-model="dialog" persistent max-width="290">
            <v-card>
              <v-card-title class="title pa-3 warning white--text">
                <v-icon class="mr-2 white--text">warning</v-icon>
                Please, wait!
              </v-card-title>
              <v-card-text class="ma-0"
                ><br />
                Are you sure you want to rollback this transaction?
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="success" outlined small @click="dialog = false"
                  >No</v-btn
                >
                <v-btn
                  color="warning"
                  outlined
                  small
                  @click="(reasonDialog = true), (dialog = false)"
                  >Yes
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="reasonDialog" persistent max-width="600">
            <v-card>
              <v-card-title class="title pa-3 warning white--text">
                <v-icon class="mr-2 white--text">warning</v-icon>
                Rollback Reason!
              </v-card-title>

              <v-card-title class="title pa-3">
                <v-textarea
                  outlined
                  name="input-7-4"
                  label="Reason to Rollback"
                  v-model="reason"
                ></v-textarea>

                <br />
                <alert-message
                  :extraSmall="true"
                  type="warning"
                  :title="'Rollback!! (' + $auth.user().full_name + ')'"
                  message="Make sure what you are doing. Frequent rollback actions might cause issue on the billing process"
                />
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="success"
                  outlined
                  small
                  @click="reasonDialog = false"
                  >Cancel</v-btn
                >
                <v-btn
                  :disabled="reason.length < 3"
                  color="warning"
                  outlined
                  small
                  @click="transactionRollBack"
                >
                  Rollback
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="correctionDialog" persistent max-width="800">
            <v-card>
              <v-card-title class="title pa-3 warning white--text">
                <v-icon class="mr-2 white--text">warning</v-icon>
                Invoice Details Id Correction
              </v-card-title>
              <v-card-text class="ma-0"
                ><br />
                <!-- {{ correctionTransactionData }} -->
                <div
                  v-for="(transactionItem, key) in correctionTransactionData"
                  :key="key"
                >
                  <!-- {{ transactionItem.transaction_record }} -->
                  <p>
                    Bill No : <strong>{{ transactionItem.bill_no }}</strong>
                    , Invoice No :
                    <strong>{{ transactionItem.invoice_no }}</strong>
                    , Paid Amount :
                    <strong>
                      {{ transactionItem.transaction_record.amount }}
                    </strong>
                    , Advance Amount :
                    <strong>
                      {{ transactionItem.transaction_record.advance_amount }}
                    </strong>
                    <br />
                    , I. Discount Amount :
                    <strong>
                      {{ transactionItem.transaction_record.instant_discount }}
                    </strong>
                  </p>
                  <p>
                    Remarks
                    <strong>{{
                      transactionItem.transaction_record.remarks
                    }}</strong>
                  </p>
                  <table border="1" width="100%">
                    <tr>
                      <th>Head</th>
                      <th>Total Amount</th>
                      <th>Paid Amount</th>
                      <th>Instant Discount</th>
                      <th>Due Amount</th>
                      <th>Action</th>
                    </tr>
                    <tr
                      v-for="(item, x) in transactionItem.invoice_details_temp"
                      :key="x + 100"
                    >
                      <td>{{ item.fee_head }}</td>
                      <td>{{ item.total }}</td>
                      <td>{{ item.paid_amount }}</td>
                      <td>{{ item.instant_discount }}</td>
                      <td>{{ item.due_amount }}</td>
                      <td>
                        <v-btn
                          @click="
                            correctDetailId(
                              item,
                              transactionItem,
                              item.fee_head === 'Previous Due'
                            )
                          "
                          v-if="
                            (item.update_stamp === 1,
                            item.paid_amount &&
                              item.updated_at.slice(0, -3) ===
                                transactionItem.transaction_record.created_at.slice(
                                  0,
                                  -3
                                ) &&
                              transactionItem.transaction_record
                                .advance_amount === 0)
                          "
                          x-small
                          outlined
                        >
                          Correct
                        </v-btn>
                      </td>
                    </tr>
                  </table>

                  <br />
                  <v-btn
                    v-if="
                      transactionItem.transaction_record.advance_amount !== 0
                    "
                    outlined
                    block
                    x-small
                    @click="correctDetailIdAll(transactionItem)"
                    >Correct All</v-btn
                  >
                  <v-btn
                    v-else
                    outlined
                    block
                    x-small
                    @click="correctDetailIdAll(transactionItem, true)"
                    >Correct All</v-btn
                  >
                </div>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="success"
                  outlined
                  small
                  @click="correctionDialog = false"
                  >Cancel</v-btn
                >
                <!-- <v-btn color="warning" outlined small>Yes </v-btn> -->
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import Form from "@/library/Form";
import TransactionVia from "@/components/app/TransactionVia";
import { validateDate } from "../../../../library/helpers";

const NepaliDate = require("nepali-date");
const nd = new NepaliDate();
import Mixins from "@/library/Mixins";

export default {
  components: {
    TransactionVia,
  },
  mixins: [Mixins],
  data: () => ({
    form: new Form(
      {
        bill_month: "",
        grade_id: "",
        enroll: "",
      },
      "/api/report/transaction"
    ),
    correctionDialog: false,
    correctionTransactionData: [],
    grade: "all",
    transaction_by: ["Cash", "Cheque", "Khalti", "Esewa"],
    from_date: nd.format("YYYY-MM-DD"),
    today_date: nd.format("YYYY-MM-DD"), //just for comparison and show "today" string
    statement_types: ["Transaction", "Advance"],
    dateValidation: [(v) => validateDate(v) || "Invalid Date Format"],
    selected_statement_type: "Transaction",
    to_date: nd.format("YYYY-MM-"),
    grades: [],
    search: null,
    expand: false,
    noData: false,
    viewDialog: false,
    enrollCode: "",
    notifications: false,
    paginationData: false,
    paginationDetailData: false,
    // transaction_type: ["Cash", "Cheque", "Khalti", "Advance"],
    sound: true,
    widgets: false,
    billNo: "",
    transactionList: [],
    gradeLoading: false,
    pagination: {
      itemsPerPage: 50,
    },
    footerProps: {
      "items-per-page-options": [10, 25, 50, 100, -1],
    },
    url: "",
    printUrl: "",
    dialog: false,
    reason: "",
    reasonDialog: false,
    items: [],
    downloadUrl: "",
    totalCollection: 0,
    totalAdvanceAmount: 0,
    totalInstantDiscount: 0,
    headers: [
      {
        text: "R. no",
        align: "left",
        value: "bill_no",
        sortable: true,
      },
      {
        text: "Transaction Date / Received At",
        align: "left",
        value: "transaction_date",
        sortable: false,
      },
      { text: "Student", align: "left", value: "name", sortable: false },
      { text: "Grade", align: "left", value: "grade", sortable: false },
      { text: "Amount", align: "left", value: "amount", sortable: false },
      {
        text: "Instant Discount",
        align: "left",
        value: "instant_discount",
        sortable: false,
      },
      { text: "Via", align: "left", value: "via", sortable: false },
      {
        text: "Received By",
        align: "left",
        value: "recieved_by",
        sortable: false,
      },
      {
        text: "Action",
        align: "right",
        value: "action",
        sortable: false,
        width: 50,
      },
    ],
    headersAdvance: [
      { text: "Bill no", align: "left", value: "bill_no", sortable: false },
      {
        text: "Transaction Date",
        align: "left",
        value: "transaction_date",
        sortable: false,
      },
      {
        text: "Student",
        align: "left",
        value: "name",
        sortable: false,
        width: 400,
      },
      { text: "Grade", align: "left", value: "grade", sortable: false },
      {
        text: "Advance Amount",
        align: "left",
        value: "amount",
        sortable: false,
      },
      { text: "Via", align: "left", value: "via", sortable: false },
      {
        text: "Received By",
        align: "left",
        value: "recieved_by",
        sortable: false,
        width: 100,
      },
    ],
  }),
  computed: {
    ...mapState(["batch"]),

    gradeName() {
      let output = "";
      let $this = this;
      this.grades.map((grade) => {
        if ($this.grade === grade.value) {
          output = grade.text;
        }
      });
      return output;
    },
    statementTypes() {
      let $this = this;
      return this.statement_types.filter(function(statement) {
        return $this.selected_statement_type !== statement;
      });
    },
  },
  mounted() {
    this.getGrades();
  },
  created() {
    if (this.$route.query.frm_date) {
      this.from_date = this.$route.query.frm_date;
    }
    if (this.$route.query.to_date) {
      this.to_date = this.$route.query.to_date;
    }
  },
  watch: {
    enrollCode: function(value) {
      this.items = [];
      this.paginationData = false;
      this.paginationDetailData = false;
    },
    grade: function() {
      this.transactionList = [];
    },
    pagination: function() {
      this.get();
    },
  },

  methods: {
    transactionCorrection(transaction) {
      this.$rest
        .get("/api/report/transaction/correction/" + transaction.bill_no)
        .then((res) => {
          if (res.data.length === 1) {
            const records = res.data.filter(
              (item) => item.invoice_detail_id === null
            );
            if (records.length) {
              this.correctionTransactionData = records.map((item) => {
                item.transaction_record = transaction;
                return item;
              });
              this.correctionDialog = true;
            }
          }
        });
    },
    correctDetailIdAll(transaction, onlySum = false) {
      if (transaction) {
        let details = transaction.invoice_details_temp;
        if (onlySum) {
          details = [];
          transaction.invoice_details_temp.map((item) => {
            if (
              item.update_stamp === 1 &&
              item.paid_amount &&
              item.updated_at.slice(0, -3) ===
                transaction.transaction_record.created_at.slice(0, -3) &&
              transaction.transaction_record.advance_amount === 0
            ) {
              details.push(item);
            }
          });
        }

        this.$rest
          .post("/api/report/transaction/correction/" + transaction.id, {
            invoice_details_temp: details,
            invoice_no: transaction.invoice.invoice_no,
            bill_no: transaction.bill_no,
          })
          .then((res) => {
            this.$events.fire("notification", {
              message: "Correction was successful",
              status: "success",
            });
            this.correctionDialog = false;
            this.get();
          })
          .catch((err) => {
            this.$events.fire("notification", {
              message: "Correction failed",
              status: "error",
            });
          });
      } else {
        alert("Correction Failed");
      }
    },
    correctDetailId(invoiceDetail, invoiceTransaction, isPreviousDues = false) {
      if (invoiceDetail && invoiceTransaction.transaction_record.amount) {
        let amount = invoiceTransaction.transaction_record.amount;
        if (invoiceTransaction.transaction_record.advance_amount) {
          amount = invoiceDetail.paid_amount;
        }
        this.$rest
          .post("/api/report/transaction/correction/" + invoiceTransaction.id, {
            invoice_detail_id: invoiceDetail.id,
            discount: invoiceDetail.instant_discount,
            paid_amount: amount,
            invoice_no: invoiceDetail.invoice_no,
            bill_no: invoiceTransaction.transaction_record.bill_no,
            detail_ids: [],
          })
          .then((res) => {
            this.$events.fire("notification", {
              message: "Correction was successful",
              status: "success",
            });
            this.correctionDialog = false;
            this.get();
          })
          .catch((err) => {
            this.$events.fire("notification", {
              message: "Correction failed",
              status: "error",
            });
          });
      } else {
        alert("Correction Failed");
      }
    },
    get(params) {
      if (!validateDate(this.to_date) || !validateDate(this.from_date)) return;

      this.noData = true;
      let via =
        this.transaction_by.length > 4 ? "all" : this.transaction_by.join(",");

      let extraParam =
        "fromDate=" +
        this.from_date.dateForm() +
        "&toDate=" +
        this.to_date.dateForm() +
        "&gradeId=" +
        this.grade.toString() +
        "&via=" +
        via +
        "&enrollCode=" +
        this.enrollCode.toString() +
        "&transactionReport=true";
      let query = [null, undefined].includes(params)
        ? this.queryString(extraParam)
        : params;
      this.form.loading = true;
      let endpoint =
        this.selected_statement_type === "Transaction"
          ? "/api/report/transaction"
          : "/api/report/advance";
      endpoint += query;
      this.$rest
        .get(endpoint)
        .then(({ data }) => {
          // console.log(data.meta.total);
          this.form.items = data;
          this.noData = false;
          this.transactionList = [];
          let $this = this;
          this.totalCollection = 0;
          this.totalInstantDiscount = 0;
          this.totalAdvanceAmount = 0;
          this.transactionList = data.data;
          this.transactionList.map((res) => {
            this.totalCollection += res.amount;
            this.totalAdvanceAmount += res.advance_amount;
            this.totalInstantDiscount += res.instant_discount;
          });
          // console.log(data.data)
          this.downloadUrl = data.downloadUrl;
          this.printUrl = data.printUrl;
        })
        .catch((e) => {})
        .finally((e) => {
          this.form.loading = false;
        });
    },

    getGrades() {
      this.gradeLoading = true;
      this.grades = [{ value: "all", text: "ALL" }];
      this.$rest
        .get(
          "/api/grades?rowsPerPage=25&sortBy=rank&descending=false&slim=true"
        )
        .then(({ data }) => {
          data.data.map((item) => {
            this.grades.push({ value: item.id, text: item.name });
          });
        })
        .finally(() => {
          this.gradeLoading = false;
        });
    },
    paymentRollback(item) {
      this.billNo = item.bill_no;
      this.dialog = true;
    },
    transactionRollBack() {
      this.$rest
        .post("/api/fee-payment/rollback-v2", {
          bill_no: this.billNo,
          reason: this.reason,
        })
        .then(({ data }) => {
          this.get();
          this.$events.fire("notification", {
            message: e.response.data.message,
            status: "error",
          });
        })
        .catch((e) => {
          this.$events.fire("notification", {
            message: e.response.data.message,
            status: "error",
          });
        });
      this.reasonDialog = false;
      this.reason = "";
    },
    print(item) {
      let url =
        this.printUrl +
        "&receipt=" +
        item.bill_no +
        "&enrollId=" +
        item.enroll_id;
      window.open(url);
    },
    clearFilter() {
      this.from_date = nd.format("YYYY-MM-DD");
      this.to_date = "";
      this.transaction_by = ["Cash", "Cheque", "Khalti", "Esewa"];
      this.grade = "all";
      this.enrollCode = "";
      this.get();
    },
    download() {
      window.open(this.url);
    },
  },
};
</script>
<style lang="scss">
table.v-datatable.v-table.theme--light {
  width: 100%;
}

.inner-table {
  border-style: double;
  background: #f1f0f0;
}

.inner-table > tr > td {
  border-style: double;
}

.statement-head {
  .v-menu__activator--active {
    background-color: transparent !important;
  }

  .v-btn {
    padding-left: 0px;
  }

  .v-btn__content {
    color: #666 !important;
    font-size: 22px;
    padding-left: 0 !important;
    text-transform: capitalize;
    font-weight: bold !important;
    border-bottom: 2px solid #666;
  }

  .tableRow {
    border-bottom: 1px solid red !important;
  }
}
</style>
